<template>
    <v-container fluid>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])" no-gutters dense>
            <v-col  cols="auto" class="d-flex">
                <CreateRolesBottomSheet v-on:close="onClose" />
            </v-col>
        </v-row>
        <v-divider v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])" :dark="$store.getters.getColorPalette().isDark" :class="'mt-3 ' + $store.getters.getColorPalette().accentCode"></v-divider>
        <v-row no-gutters dense>
            <v-col  cols="auto" class="d-flex">
                <RolesPanel :refreshData="refreshData"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import CreateRolesBottomSheet from '@/components/modals/accounts/CreateRolesBottomSheet'
import RolesPanel from '@/components/panels/accounts/RolesPanel';
export default{
    name:'Roles',
    components:{
        CreateRolesBottomSheet,
        RolesPanel
    },
    mounted(){

    },
    data(){
      return {
        refreshData:false
      }
    },
    methods:{
        onClose(){
            this.refreshData=true
        }
    }
}
</script>