<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    item-key="machine_id"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    item-class="font-weight-black text-center"
                    :style="{
                        background: $store.getters.getColorPalette().background2ColorCode,
                        color: $store.getters.getColorPalette().accentCode
                    }"
                    :dark="$store.getters.getColorPalette().isDark"
                    >
                    <template v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess'])" v-slot:item.action="{ item }">
                        <td>
                            <v-btn class="mr-2"  @click="editRole(item)" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().edit_role}}

                                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <DeleteConfirmation v-on:confirm="deleteRole(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Role?"> 
                                <v-btn :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_role}}

                                    <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                                </v-btn>
                            </DeleteConfirmation>
                        </td>
                    </template>
                    </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-dialog v-model="dialog" max-width="1000"> 
                    <CreateRoles :role="role" v-on:close="onClose" />
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateRoles from '@/components/account_components/CreateRoles'
export default {
    name:'RolesPanel',
    props:['refreshData'],
    components:{
        InfoAlert,
        DeleteConfirmation,
        CreateRoles
    },
    data(){
        return {
            tableData:[],
            dialog: false,
            loading: false,
            info: '',
            roles:[],
            role:null,
            showDismissibleAlert: false,
            headers:[
            { text: 'Role', align: 'start', value: 'role' },
            { text: 'Description', value: 'msg' },
            { text: 'Rules', value: 'role_config' },
            { text: 'Action', value: 'action' },
            ]
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        createTableData(){
            this.tableData=[]
            for(let i of this.roles){
                let temp={}
                temp['role']=i.role
                temp['msg']=i.msg
                temp['role_config']=JSON.stringify(i.role_config)
                // temp['rules']=i.role_config
                this.tableData.push(temp)
            }
        },
        getData(){
            axios.get(this.$store.state.api + 'roles',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
                if (response.data.status == "success") {
                    this.roles=response.data.roles
                    this.createTableData()
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                }
                this.loading = false;
              })
              .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        deleteRole(item){
            axios.post(this.$store.state.api + 'deleteRoles',{role:item.role},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
                if (response.data.status == "success") {
                    this.getData()
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                }
                this.loading = false;
              })
              .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        editRole(item) {
            this.dialog=!this.dialog
            this.role=item
        },
        onClose(){
            this.dialog=!this.dialog
            this.role=null
            this.getData()
        }
    },
    watch:{
        refreshData(){
            this.getData()
        }
    }
}
</script>