<template>
    <div>
      <v-btn :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text @click="dialog = !dialog">
        {{ $store.getters.getTextMap().create_role }}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-shield-lock-outline</v-icon>
      </v-btn>
      <!-- <v-divider :color="$store.getters.getColorPalette().accentColor" class="my-5"></v-divider> -->
        <CreateRoles v-if="dialog" v-on:close="onClose" />
      </div>
  </template>
  <script>
  import CreateRoles from '@/components/account_components/CreateRoles'
    export default {
        name:'CreateRolesBottomSheet',
        components:{
            CreateRoles
        },
      data () {
        return {
          dialog: false,
          valid:false,
        }
      },
      methods:{
          onClose(){
              this.dialog=false
              this.$emit('close')
              }
      }
    }
  </script>