<template>
    <v-container fluid>
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat outlined class="pa-5" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '0px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
            <v-form ref="form"
            v-model="valid">
            <v-row>
              <v-col v-if="!edit">
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                  v-model="form.role"
                  :counter="40"
                  :rules="nameRules"
                  outlined
                  dense
                  :label="$store.getters.getTextMap().role"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                  :color="$store.getters.getColorPalette().inputtextColor"
                  v-if="!loading"
                  v-model="form.msg"
                  :counter="100"
                  outlined 
                  dense
                  :label="$store.getters.getTextMap().description"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card-title>{{$store.getters.getTextMap().Set}} {{$store.getters.getTextMap().rules}}<InfoComponent hint='{"enture_views":{"actions":["read"],"resources":["test_id"]},"workspaces":{"actions":["*"],"resources":["*"]}} This JSON structure is an example of permissions for enture_views and workspaces, where "actions" can include various operations (e.g., read, write), and "resources" represent the ID these actions apply to. The "*" wildcard means all actions or resources are allowed.' /></v-card-title>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea
                  filled
                  :label="$store.getters.getTextMap().rules"
                  v-model="rules"
                  auto-grow
                  clearable
                  clear-icon="mdi-close-circle"
                  cols="200"
                ></v-textarea>
              </v-col> 
            </v-row>
            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" outlined text @click="onSubmit">{{mode}}</v-btn>
            <v-snackbar v-model="errorSnackbar" color="error" top>{{ errorMessage }}</v-snackbar>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios'
  import InfoAlert from '@/components/InfoAlert'
import InfoComponent from '@/components/InfoComponent'
  export default {
    name: 'CreateRoles',
    props:['role'],
    components:{
        InfoAlert,
        InfoComponent
    },
    data() {
      return {
        dialog: false,
        rules: null,
        loading:false,
        edit:false,
        showDismissibleAlert:false,
        info:'',
        valid:false,
        api:'createRoles',
        form:{
          role:null,
          msg:null
        },
        mode:this.$store.getters.getTextMap().create,
        errorSnackbar: false,
        errorMessage: '',
        nameRules: [
          v => !!v || this.$store.getters.getTextMap().role_is_required,
          v => /\S+/.test(v) || this.$store.getters.getTextMap().role_is_required,
          v => (v && v.length <= 40 && v.length > 0) || this.$store.getters.getTextMap().role_must_be_less_than_40_characters,
          v => !/\s/.test(v) || this.$store.getters.getTextMap().role_should_not_contain_white_characters,
        ],
        msgRules: [
                v => !!v || this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 30 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_30_characters,
            ],
      };
    },
    mounted(){
      if(this.role&&this.role.role){
        this.api='updateRoles'
        this.edit=true
        this.mode='update'
        this.form = Object.assign({},this.role)
        this.rules=this.role.role_config
      }
    },
    methods: {
      onClose() {
        this.dialog = false;
        this.$emit('close');
      },
      onSubmit() {
        try {
          if(this.rules){
            const parsedRules = JSON.parse(this.rules);
            this.$refs.form.validate()
            if(this.valid){
              this.loading = true;
              this.form['roles_config']=parsedRules
              axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
                if (response.data.status == "success") {
                  this.loading = false;
                  this.$store.dispatch('refreshRoles')
                  this.$emit('close')
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                }
                this.loading = false;
              })
              .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
              });
            }
          }else{
            this.errorSnackbar = true;
            this.errorMessage = 'Rules cannot be empty';            
          }
        } catch (error) {
          this.errorSnackbar = true;
          this.errorMessage = 'Invalid JSON format. Please enter valid JSON.';
        }
      },
    },
  };
  </script>
  